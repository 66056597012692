/*
 *  Radius
*/
/*
 * Config
 * @ Variables
 *
*/
/*
 *  Use Defaults
*/
/*
 *  FWAD base Font size
*/
/*
 * H1
*/
/*
 * header fonts mobile devices
 */
/*
 * header fonts tablets(portrait)
 */
/*
 * header fonts tablets(landscape) and small desktop
 */
/*
 * header fonts large desktop
 */
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*/*
 * body-2
*/
/*
 * body-3
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ar-1
*/
/*/*
 * body-ar-2
*/
/*
 * body-ar-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-ru-1
*/
/*/*
 * body-ru-2
*/
/*
 * body-ru-3
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-zh-1
*/
/*/*
 * body-zh-2
*/
/*
 * body-zh-3
*/
/*
* z-index variables
*/
/*
* form placeholder color variables
*/
/*
 * Background Image
 */
/*
  * Line height font
  */
/*
  * Border Radius
  */
/*
  * Box Sizing
  */
/*
  * Box Shadow
  */
/*
  * Border Radius Separate
  */
/*
  * Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * Wide screen Desktop (PC's)
  */
/*
  * large screen Desktop (PC's)
  */
/*
  * Wide screen Devices
  */
/*
  * upto Tablet
  */
/*
  * Tablet
  */
/*
  * TabletOnly
  */
/*
  * Mobile
  */
/*
  * Transition
     usage example
       a{
       color: gray;
       @include transition(color .3s ease);
       &:hover {
         color: black;
       }
 }
  */
/*
  * Opacity
  */
/*
  * Clearfix
  */
/*
  * Configure vendor
  */
/*
  * Transform
  */
/**
 *   Use Arrow inside the :after and :before pseudo elements
 *   and change the position as per needed
 *   @include arrows('right', 7px, 7px, #000);
 **/
/**
 *   Background gradient mixin
 **/
/*
  * Flex
  */
/*
 * @ Fonts
 *
*/
/*
 * Base
 * @ Colors
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
.c-tabs-with-placeholder {
  display: inline-block;
  width: 100%;
  /* < 768px */
}
.c-tabs-with-placeholder .component-title {
  max-width: 1320px;
  margin: auto;
  text-align: center;
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-tabs-with-placeholder {
    width: 95.2380952381%;
    max-width: 731.4285714286px;
    margin-left: 2.380952381%;
    margin-right: 2.380952381%;
  }
}
.c-tabs-with-placeholder .tabs-menu {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
.c-tabs-with-placeholder .tabs-menu--links {
  position: relative;
  border-bottom: 2px solid #d3d3d3;
  color: #000000;
  opacity: 1;
  padding-bottom: 0;
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ar .c-tabs-with-placeholder .tabs-menu--links {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-ru .c-tabs-with-placeholder .tabs-menu--links {
  font-family: "Noto Sans Black", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
body.lang-zh .c-tabs-with-placeholder .tabs-menu--links {
  font-family: "Norwester", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-tabs-with-placeholder .tabs-menu--links {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-menu--links {
    padding-bottom: 17px;
  }
}
.c-tabs-with-placeholder .tabs-menu--links.is-active {
  color: #e50000;
  border-color: #e50000;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-menu--links.is-active::after {
    background-color: #ffffff;
    border: 1px solid #e50000;
    border-width: 0px 2px 2px 0px;
    border-top-color: transparent;
    border-left-color: transparent;
    content: "";
    display: block;
    height: 12px;
    left: calc(50% - 6px);
    position: absolute;
    bottom: -7px;
    width: 12px;
    -webkit-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .tabs-menu {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    padding-left: 1.3440860215%;
    padding-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-menu {
    width: 97.9674796748%;
    max-width: 1338.2357723577px;
    padding-left: 1.0162601626%;
    padding-right: 1.0162601626%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .tabs-menu {
    width: 81.5151515152%;
    max-width: 1296px;
    padding-left: 0.9090909091%;
    padding-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown {
  /* < 768px */
  width: 100%;
  margin: 40px 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 320px) and (max-width: 767px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    display: block;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu-mobile .tabs-menu--links {
  padding: 15px 18px;
  border-bottom: 4px solid #d8d8d8;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu-mobile .tabs-menu--links.is-active {
  border-color: #e50000;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  line-height: 29px;
}
.c-tabs-with-placeholder .v-tabs-dropdown div.tab-menu-dropdown-container {
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  padding: 15px 18px;
  border: 1px solid #d3d3d3;
  border-radius: 0;
  height: auto;
  text-align: left;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown div.tab-menu-dropdown-container {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  position: static;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  color: #333333;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown strong {
  font-weight: 700;
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
body.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown strong {
  font-weight: 700;
}
@media (min-width: 768px) {
  body.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
body.lang-ru .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown strong {
  font-weight: 700;
}
@media (min-width: 768px) {
  body.lang-ru .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
body.lang-zh .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown strong {
  font-weight: 700;
}
@media (min-width: 768px) {
  body.lang-zh .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-head-length {
  display: none;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot {
  transform: none;
  border: solid #d3d3d3;
  border-width: 0 0 0 1px;
  height: 57px;
  top: 0;
  width: 54px;
  right: 0;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot::after {
  content: "";
  height: 28px;
  position: absolute;
  width: 28px;
  z-index: -1;
  right: 13px;
  top: 13px;
  border: none;
  background-image: url("../../../assets/img/arrow-dropdown.png");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transform: none;
  -khtml-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
}
.c-tabs-with-placeholder .v-tabs-dropdown.expand-dropdown .tab-menu-dropdown-carrot {
  transform: none;
}
.c-tabs-with-placeholder .v-tabs-dropdown.expand-dropdown .tab-menu-dropdown-carrot::after {
  -webkit-transform: rotate(180deg);
  -khtml-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
  max-width: 100%;
  position: static;
  border-radius: 0;
  border: 1px solid #d3d3d3;
  border-top: 0;
  width: 95.2380952381%;
  max-width: 731.4285714286px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 81.3008130081%;
    max-width: 1110.5691056911px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
    padding: 0;
    border: none;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu:before {
  content: none;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    margin-bottom: 0;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section {
  font-family: "Open Sans", Arial, sans-serif;
  /* Medium devices (desktops, 1024px and up) */
}
.lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section {
    width: 95.2380952381%;
    max-width: 731.4285714286px;
    margin-left: 2.380952381%;
    margin-right: 2.380952381%;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section {
    width: 81.3008130081%;
    max-width: 1110.5691056911px;
    margin-left: 1.0162601626%;
    margin-right: 1.0162601626%;
    margin: 0 auto;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .upper-border.brand-color {
  background: #e50000;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .header-brand-color {
  background: #ffeaea;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .section-label-header {
  font-family: "Norwester", "Open Sans", Arial, sans-serif;
  font-weight: normal;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper {
  text-align: center;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew {
  display: inline-block;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile {
  background: #e50000;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.disabled {
  opacity: 0.3;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 10px 36px;
  font-weight: 800;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile a {
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 8px 36px;
}
.lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile a {
  font-weight: 700;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .disabled {
  pointer-events: none;
  opacity: 0.3;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .no-annual-pass-section .btn-subscribe {
  width: auto;
  margin: 0 auto;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .no-annual-pass-section .btn-subscribe a {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  padding: 10px 30px;
}
.c-tabs-with-placeholder .c-collapsible-content.component {
  margin-bottom: 0;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .c-collapsible-content.component {
    margin-top: 13px;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget {
  display: block;
  z-index: 3;
  margin-top: 30px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget {
    margin-top: 0;
    top: 100px;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .component-wrapper {
  padding: 40px 18px 22px 18px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .component-wrapper {
    padding: 0;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu .tabs-menu--links.is-active {
    z-index: 6;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu .tabs-menu--links:nth-child(2) {
    z-index: 5;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu .tabs-menu--links:after {
    box-shadow: none;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu {
  background-color: #999999;
  color: #999999;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu {
    background: transparent;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu.focus-within .tabs-menu--links:after {
    box-shadow: none;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links {
  background: #fff5f5;
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 0 !important;
  color: #d52222;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-ar .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-ru .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Open Sans", "PingFang SC", "STHeiti", "Microsoft YaHei";
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1366px) {
  body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  body.lang-zh .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    border-radius: 0 10px 0 0;
    letter-spacing: 1px;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links {
    color: #999999;
    background: transparent;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:after {
    background: #ffffff;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
    background: transparent;
    color: #d52222;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(even):after {
    background: #fff5f5;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
  background: #d52222;
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) a {
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) a {
    color: #d52222;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
    background: transparent;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(even):after {
  background: #fff5f5;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active {
  background: #d52222;
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active:after {
  background: #d52222;
  width: 100%;
  height: 100%;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active a {
  color: #ffffff;
  font-weight: 900;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active {
    background: transparent;
  }
}